import React, {Component} from 'react'
import axios from 'axios'
import {Form, Field} from 'react-final-form'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import ErrorIcon from '@material-ui/icons/ErrorRounded'
import SuccessIcon from '@material-ui/icons/CheckCircleRounded'

import InputField from './InputField'
import {COLORS} from '../../../styles/theme'
import './style.scss'

axios.defaults.baseURL = process.env.REACT_APP_API_URL

export const regExps = {
  // eslint-disable-next-line
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
}

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}

const styles = {
  root: {
    fontSize: 24,
    color: 'white',
  },
}

const OffButton = withStyles({
  root: {
    backgroundColor: COLORS.BLACK_TWO_01,
  },
})(Button)

const OnButton = withStyles({
  root: {
    backgroundColor: 'white',
  },
  label: {
    color: COLORS.black,
  },
})(Button)

const StyledSuccessIcon = withStyles({...styles})(SuccessIcon)

const StyledErrorIcon = withStyles({...styles})(ErrorIcon)

export const validate = values => {
  const errors = {}
  if (!values.name) errors.name = 'enter name'
  if (!values.email || !regExps.email.test(values.email)) errors.email = 'email incorrect'
  if (values.role === roles[1] && !values.place) errors.place = 'enter eatery name'
  if (!values.message) errors.message = 'enter message'
  return errors
}

const roles = ['Customer', 'Partner']

const initialValues = {
  role: 'Customer',
}

export default class ContactForm extends Component {
  state = {
    isFetch: false,
    isSuccess: false,
    isError: false,
  }

  handleFormSubmit = ({place, role, ...values}, form) => {
    const data = {
      ...values
    }
    if (role === roles[1]) {
      data.place = place
    }
    this.setState({isFetch: true})
    axios
      .post('/api/mail/contact-us', data)
      .then(() => {
        this.setState({isFetch: false, isSuccess: true})
        form.reset()
      })
      .catch(() => this.setState({isFetch: false, isError: true}))
  }

  handleSuccessClose = () => this.setState({isSuccess: false})

  handleErrorClose = () => this.setState({isError: false})

  renderForm = ({values, handleSubmit, hasValidationErrors}) => {
    const {isFetch, isSuccess, isError} = this.state
    const ButtonComponent = hasValidationErrors ? OffButton : OnButton
    return (
      <form noValidate className="ContactForm" autoComplete="off" onSubmit={handleSubmit}>
        <div>
          <Field id="name" name="name" placeholder="Name" component={InputField} />
          <Field isCheck name="email" type="email" placeholder="Email" component={InputField} />
          <Field name="role" options={roles} component={InputField} />
          {values.role === roles[1] && <Field name="place" placeholder="Eatery name" component={InputField} />}
          <Field multiline name="message" rowsMax={8} placeholder="Message" component={InputField} />
        </div>
        <div className="ContactForm-Submit">
          <ButtonComponent
            fullWidth
            type="submit"
            color={hasValidationErrors ? 'secondary' : null}
            variant="contained"
            disabled={isFetch}
          >
            Send message
          </ButtonComponent>
        </div>
        <Snackbar
          open={isSuccess}
          anchorOrigin={anchorOrigin}
          autoHideDuration={6000}
          message={
            <div className="ContactForm-Message">
              <StyledSuccessIcon />
              <span className="ContactForm-MessageText">Your message has been successfully sent.</span>
            </div>
          }
          onClose={this.handleSuccessClose}
        />
        <Snackbar
          open={isError}
          anchorOrigin={anchorOrigin}
          message={
            <div className="ContactForm-Message">
              <StyledErrorIcon />
              <span className="ContactForm-MessageText">Something went wrong.</span>
            </div>
          }
          onClose={this.handleErrorClose}
        />
      </form>
    )
  }

  render() {
    return (
      <Form
        initialValues={initialValues}
        validate={validate}
        onSubmit={this.handleFormSubmit}
        render={this.renderForm}
      />
    )
  }
}
