import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {ReactComponent as FacebookIcon} from '../../../assets/icons/facebook.svg'
import {ReactComponent as InstagramIcon} from '../../../assets/icons/instagram.svg'
import './style.scss'

const links = [
  {id: 1, Icon: FacebookIcon, title: 'Facebook', href: 'https://www.facebook.com/'},
  {id: 2, Icon: InstagramIcon, title: 'Instagram', href: 'https://www.instagram.com/'},
]

Social.propTypes = {
  isMenu: PropTypes.bool,
}

export default function Social({isMenu}) {
  return (
    <ul className={cn('Social', {Social_menu: isMenu})}>
      {links.map(link => (
        <li key={link.id} className="Social-Item">
          <a
            className={cn('Social-Link Link', {Link_white: !isMenu})}
            aria-label={link.title}
            target="_blank"
            rel="noopener noreferrer"
            href={link.href}
          >
            <link.Icon className="Icon" />
          </a>
        </li>
      ))}
    </ul>
  )
}
