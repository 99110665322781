import React from 'react'
import PropTypes from 'prop-types'
import withWidth from '@material-ui/core/withWidth'
import Grid from '@material-ui/core/Grid'

import ScrollAnimation from '../_common/ScrollAnimation'
import {ReactComponent as CustomerIcon} from '../../assets/icons/customer.svg'
import {ReactComponent as FoodIcon} from '../../assets/icons/food.svg'
import {ReactComponent as CardsIcon} from '../../assets/icons/cards.svg'
import {ReactComponent as EnvironmentIcon} from '../../assets/icons/environment.svg'
import './style.scss'

const particularities = [
  {
    id: 1,
    Icon: CustomerIcon,
    title: 'New Customers ',
    text: 'Foodprint connects your eatery to new customers',
  },
  {id: 2, Icon: FoodIcon, title: 'Reduce Food Waste', text: 'Your food waste and food waste costs are reduced'},
  {id: 3, Icon: CardsIcon, title: 'Earn money', text: "Allow your food to retain it's value"},
  {id: 4, Icon: EnvironmentIcon, title: 'Enviroment', text: 'Join our environmentally conscious community'},
]

Particularities.propTypes = {
  width: PropTypes.string.isRequired,
}

function Particularities({width}) {
  return (
    <section className="Particularities Section">
      <h2 className="VisuallyHidden">Particularities</h2>
      <div className="Container">
        <Grid container component="ul">
          {particularities.map((particularity, index) => (
            <Grid item key={particularity.id} component="li" className="Particularities-Item" xs={12} sm={6} md={3}>
              <ScrollAnimation
                className="Particularities-ItemBlock"
                effect="fadeInBottom"
                delay={width !== 'xs' && width !== 'sm' ? 100 * index : 0}
              >
                <particularity.Icon className="Particularities-ItemIcon" />
                <h3 className="Particularities-ItemTitle">{particularity.title}</h3>
                <p className="Particularities-ItemText">{particularity.text}</p>
              </ScrollAnimation>
            </Grid>
          ))}
        </Grid>
      </div>
    </section>
  )
}

export default withWidth()(Particularities)
