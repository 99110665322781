import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'

import {UNIT} from '../../../styles/theme'
import './style.scss'

const contactsStyle = {
  marginRight: 0,
  marginBottom: UNIT * 2,
}

const StyledButton = withStyles({
  root: {
    width: 160,
    height: 40,
    borderRadius: 12,
    borderTopLeftRadius: 0,
    '&:not(:last-child)': {
      marginRight: UNIT * 2,
    },
    '@media (max-width: 599px)': {
      '&:not(:last-child)': {
        marginRight: 0,
      },
      marginBottom: UNIT * 2,
    },
  },
})(Button)

StoreButtons.propTypes = {
  isContacts: PropTypes.bool,
}

export default function StoreButtons({isContacts}) {
  const style = isContacts ? contactsStyle : null
  return (
    <div className={cn('StoreButtons', {StoreButtons_contacts: isContacts})}>
      <StyledButton
        component="a"
        color="primary"
        variant="contained"
        href="https://www.apple.com"
        target="_blank"
        rel="noopener noreferrer"
        style={style}
      >
        Appstore
      </StyledButton>
      <StyledButton
        component="a"
        color="primary"
        variant="contained"
        href="https://play.google.com/store/apps"
        target="_blank"
        rel="noopener noreferrer"
        style={style}
      >
        Google play
      </StyledButton>
    </div>
  )
}
